<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" v-if="GetAllWaterInletData[0] != undefined">
            MTD Type:<span class="pl-4 font-weight-bold">{{ GetAllWaterInletData[0].mtd_type.replace("_", " ") }}</span>
            <v-card class="elevation-0 mt-5 pt-16">
              <v-data-table dense :items="GetAllWaterInletData" hide-default-footer>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(header, idx) in GroupBarChartHeaders" :key="idx">
                      <td>
                        {{ header.text }}
                      </td>
                      <td v-for="(item, index) in items" :key="index">
                        {{ item[header.value] != null ? item[header.value].toFixed(2) : "-" }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="8" class="pa-0" align="center">
            <v-card-actions class="pa-0 pt-3">
              <v-spacer></v-spacer>
              <v-select outlined dense v-model="SelectMTDType" label="Select MTD Type" class="FontSize field_label_size field_height maxWidthStatus pr-2" :items="MtdTypeSelection"></v-select>
              <v-select
                v-if="SelectMTDType == 'WATER_PRODUCED'"
                outlined
                dense
                v-model="selectPlantType"
                label="Select Plant Type"
                class="FontSize field_label_size field_height maxWidthStatus"
                :items="PlantTypesSelection"
              ></v-select>
              <v-menu v-model="selectDateMenu" :close-on-content-click="false" :nudge-left="150" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Required']"
                    class="field_label_size field_height maxWidthStatus ml-2 FontSize mt-"
                    v-model="input_date"
                    label="Select Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="input_date" :max="new Date().toISOString().substr(0, 10)" @input="selectDateMenu = false"></v-date-picker>
              </v-menu>
            </v-card-actions>
            <apexchart height="200" type="bar" :options="chartOptions" :series="series"></apexchart>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getMTDdata } from "@/mixins/GetAllMTDData.js";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  components: {
    Overlay,
  },
  mixins: [getMTDdata],
  data: () => ({
    overlay: false,
    input_date: new Date().toISOString().substr(0, 10),
    selectDateMenu: false,
    selectPlantType: "UF",
    SelectMTDType: "WATER_PRODUCED",
    MtdTypeSelection: [
      {
        text: "Water Inlet",
        value: "WATER_INLET",
      },
      {
        text: "Water Produced",
        value: "WATER_PRODUCED",
      },
    ],
    PlantTypesSelection: [
      {
        text: "UF",
        value: "UF",
      },
      {
        text: "RO1",
        value: "RO1",
      },
      {
        text: "RO2",
        value: "RO2",
      },
    ],
    GroupBarChartHeaders: [
      {
        text: "MTD (M³)",
        value: "mtd_value",
      },
      {
        text: "Prior MTD (M³)",
        value: "prior_mtd_value",
      },
      {
        text: "MOM%",
        value: "mom_percentage",
      },
    ],
    chartOptions: {
      colors: ["#ffd966", "#9bc2e6", "#ffd966", "#9bc2e6"],
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "100%",
          dataLabels: {
            borderRadius: 4,
            position: "bottom",
          },
        },
      },

      fill: {
        colors: ["#ffd966", "#9bc2e6", "#ffd966", "#9bc2e6"],
      },
      dataLabels: {
        enabled: true,

        offsetX: 20,
        style: {
          fontSize: "12px",
          colors: ["#000000"],
        },
      },
      stroke: {
        width: 5,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["MTD Rated", "MTD Actual", "Prior MTD Rated", "Prior MTD Actual"],
        position: "top",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        categories: ["11", "22", "33", "44"],
      },
      legend: {
        show: false,
      },
    },
  }),
  watch: {
    selectPlantType(val) {
      this.GetAllMTDdataMethods(val, this.input_date, this.SelectMTDType);
    },
    input_date(val) {
      this.GetAllMTDdataMethods(this.selectPlantType, val, this.SelectMTDType);
    },
    SelectMTDType(val) {
      if (val == "WATER_INLET") {
        this.GetAllMTDdataMethods("UF", this.input_date, val);
      } else {
        this.GetAllMTDdataMethods(this.selectPlantType, this.input_date, val);
      }
    },
  },
  computed: {
    series() {
      return [
        {
          name: "MTD Rated",
          data: this.GetAllWaterInletData.map((item) => item.mtd_rated),
          colors: ["yellow"],
        },
        {
          name: "MTD Actual",
          data: this.GetAllWaterInletData.map((item) => item.mtd_actual.toFixed(2)),
        },
        {
          name: "Prior MTD Rated",
          data: this.GetAllWaterInletData.map((item) => item.prior_mtd_rated),
        },
        {
          name: "Prior MTD Actual",
          data: this.GetAllWaterInletData.map((item) => item.prior_mtd_actual.toFixed(2)),
        },
      ];
    },
  },
  mounted() {
    this.GetAllMTDdataMethods(this.selectPlantType, this.input_date, this.SelectMTDType);
  },
  methods: {},
};
</script>

<style scoped>
th,
td {
  border: 1px solid;
  column-width: 900px;
}
.width {
  width: 20px;
}
</style>
