<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog width="600" :value="FilterYearAndMonthDialog">
      <v-card class="elevation-0">
        <v-toolbar dense dark color="primary" class="elevation-0">
          <div>Select Year And Month</div>
          <v-spacer></v-spacer>
          <v-btn icon small @click="FilterYearAndMonthDialogEmit((Toggle = 1))"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="filterYearAndMonth">
            <v-card-actions class="mt-2">
              <v-select
                :items="GetYear"
                v-model="SelectYear"
                outlined
                dense
                label="Select Year"
                :rules="[(v) => !!v || 'Required']"
                class="FontSize field_label_size field_height maxWidthLarge mr-2"
              ></v-select>
              <v-select
                :items="GetMonth"
                v-model="SelectMonth"
                item-text="text"
                item-value="value"
                outlined
                dense
                multiple
                return-object
                label="Select Month"
                :rules="[(v) => !!v || 'Required']"
                class="FontSize field_label_size field_height maxWidthLarge mr-2"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip x-small v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"> (+{{ SelectMonth.length - 1 }} others) </span>
                </template></v-select
              >
              <v-btn small class="mt-n7" :disabled="!filterYearAndMonth" color="primary" @click="validateForm()">Add</v-btn>
            </v-card-actions>
          </v-form>
          <v-data-table dense class="elevation-0" :headers="inputDatesHeader" :items="input_dates">
            <template v-slot:[`item.year`]="{ item }">
              <div class="fontSize">
                {{ item.year }}
              </div>
            </template>
            <template v-slot:[`item.month`]="{ item }">
              <div class="fontSize">
                {{ item.month }}
              </div>
            </template>
            <template v-slot:[`item.Action`]="{ item }">
              <v-icon color="red" @click="input_dates.splice(input_dates.indexOf(item), 1)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn small outlined dense @click="FilterYearAndMonthDialogEmit((Toggle = 1))">Cancel</v-btn>
          <v-btn small color="primary" dark dense :loading="loading" @click="getDateForKPI()">Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetKPIs } from "@/mixins/GetKPIs.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    FilterYearAndMonthDialog: Boolean,
  },
  mixins: [GetKPIs],
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    SelectYear: "",
    GetYear: [],
    input_dates: [],
    SelectMonth: [],
    GetMonth: [
      { text: "January", value: 1 },
      { text: "Feburary", value: 2 },
      { text: "March", value: 3 },
      { text: "April", value: 4 },
      { text: "May", value: 5 },
      { text: "June", value: 6 },
      { text: "July", value: 7 },
      { text: "August", value: 8 },
      { text: "September", value: 9 },
      { text: "October", value: 10 },
      { text: "November", value: 11 },
      { text: "December", value: 12 },
    ],
    SnackBarComponent: {},
    inputDatesHeader: [
      {
        text: "Year",
        value: "year",
      },
      {
        text: "Month",
        value: "month_text",
      },
      {
        text: "Action",
        value: "Action",
      },
    ],
    loading: false,
    filterYearAndMonth: false,
  }),
  mounted() {
    this.GetDateYear();
  },
  methods: {
    async getDateForKPI() {
      let result = await this.GetKPIsMethod(
        this.input_dates.map((date) => {
          let dateObject = {
            year: date.year,
            month: date.month,
          };
          return dateObject;
        }),
        "DIALOG"
      );
      this.FilterYearAndMonthDialogEmit(2, result);
    },
    addDataToTable() {
      for (let i = 0; i < this.SelectMonth.length; i++) {
        let duplicate_data_found = false;
        if (this.input_dates.filter((date) => date.year == this.SelectYear && date.month == this.SelectMonth[i].value).length != 0) {
          duplicate_data_found = true;
        }
        if (duplicate_data_found == false) {
          this.input_dates.push({
            year: this.SelectYear,
            month: this.SelectMonth[i].value,
            month_text: this.SelectMonth[i].text,
          });
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Selected Month & Year Already Exists",
          };
        }
      }
    },
    validateForm() {
      if (this.$refs.form.validate) {
        this.addDataToTable();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    GetDateYear() {
      var Max = new Date().getFullYear();
      var Min = Max - 3;
      for (let i = Max; i >= Min; i--) {
        this.GetYear.push(i);
      }
    },
    FilterYearAndMonthDialogEmit(Toggle, response_array) {
      if (this.$refs.form) this.$refs.form.reset();
      this.SelectYear = "";
      this.SelectMonth = [];
      this.$emit("clicked", Toggle, response_array);
    },
  },
};
</script>
