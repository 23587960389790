<template>
  <v-card outlined v-if="mounted == true">
    <v-card-actions class="pa-0">
      <div class="py-0 pa-3 pt-4 FontSize font-weight-bold">Water Inlet/Outlet</div>
      <div class="FontSize pt-4">
        <span class="mx-1">Rated capacity:</span>
        <span class="mx-1">Water Inlet : 900 M³/Day</span>
        <span class="mx-1">UF : 800 M³/Day </span>
        <span class="mx-1">RO1 : 560 M³/Day</span>
        <span class="mx-1">RO2 : 130 M³/Day </span>
      </div>
      <v-spacer></v-spacer>
      <v-checkbox v-model="displayChart" dense label="View Bar Chart" class="pr-3"></v-checkbox>
    </v-card-actions>
    <!-- <apexchart
      v-if="displayChart == true"
      class="px-12"
      type="bar"
      height="268"
      :options="chartOptions"
      :series="series"
    ></apexchart> -->
    <apexchart
      class="mt"
      :type="displayChart == true ? 'bar' : 'line'"
      height="235"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </v-card>
</template>
<script>
export default {
  props: {
    KPIsArray: Array,
  },
  data: () => ({
    mounted: true,
    displayChart: true,
    chartOptions: {
      colors: ["#fc0303", "#fca903", "#d2fc03", "#07fc03", "#03fceb", "#0384fc", "#9d03fc", "#fc0384"],
      legend: {
        show: true,
        position: "right",
      },
      chart: {
        width: 100,
        height: 350,
        type: "bar",

        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // curve: "straight",
        width: 2,
        color: "#fff",
      },
      title: {
        align: "left",
      },
      // grid: {
      //   row: {
      //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //     opacity: 0.1,
      //   },
      // },
      xaxis: {
        categories: [],
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: true,
        },
        title: {
          text: "Unit (M³)",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
    },
    series: [
      {
        name: "Monthly Plant Water Inlet (M³)",
        data: [],
      },
      {
        name: "Monthly Inlet Rated Capacity",
        data: [],
      },
      {
        name: "Monthly Water Produced by UF (M³)",
        data: [],
      },
      {
        name: "UF Water Produced Rated Capacity",
        data: [],
      },
      {
        name: "Monthly Water Produced by RO1(M³)",
        data: [],
      },

      {
        name: "RO1 Water Produced Rated Capacity",
        data: [],
      },
      {
        name: "Monthly Water Produced by RO2(M³)",
        data: [],
      },

      {
        name: "RO2 Water Produced Rated Capacity(M³)",
        // type: this.displayChart == true ? "bar" : "line",
        data: [],
      },
    ],
  }),
  mounted() {
    // console.log("Check KPIsArray", this.KPIsArray);
    setTimeout(() => {
      this.formLineChartData();
    }, 1000);
  },
  methods: {
    formLineChartData() {
      console.log("KPIsArray BArChart", this.KPIsArray);
      /*Sarika's Logic
     manisresponse = Array[]
      for(let i=0;i<manisresponse.length;i++){
        this is for x-axis labels :
        this.chartOptions.xaxis.categories.push(manisresponse[i][0])


        This is for lines 1:
        this.series[0].data.push(manisresponse[i][1])

        This is for lines 2:
        this.series[1].data.push(manisresponse[i][2])

        This is for lines 2:
        this.series[2].data.push(manisresponse[i][3])
      }




      // =======================================
*/

      this.mounted = false;
      this.$nextTick(() => {
        this.chartOptions.xaxis.categories = this.KPIsArray.map(
          (month) => `${month.month_text.slice(0, 3)}-${month.kpi_year.toString().slice(2, 4)}`
        );
        this.series[0].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0 ? 0 : Number(month.kpis_array[16].kpi_value).toFixed(2)
        );
        this.series[1].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0 ? 0 : 900 * new Date(month.kpi_year, month.kpi_month, 0).getDate()
        );
        // this.series[1].data = this.KPIsArray.map((month) =>
        //   month.kpis_array.length == 0
        //     ? 0
        //     : month.kpis_array[15].kpi_value * new Date(month.kpi_year, month.kpi_month, 0).getDate().toFixed(2)
        // );
        this.series[2].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0 ? 0 : month.kpis_array[14].kpi_value.toFixed(2)
        );
        this.series[3].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0 ? 0 : 800 * new Date(month.kpi_year, month.kpi_month, 0).getDate()
        );
        // this.series[3].data = this.KPIsArray.map((month) =>
        //   month.kpis_array.length == 0 ? 0 : month.kpis_array[12].kpi_value.toFixed(2)
        // );
        this.series[4].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0 ? 0 : month.kpis_array[6].kpi_value.toFixed(2)
        );
        this.series[5].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0 ? 0 : 560 * new Date(month.kpi_year, month.kpi_month, 0).getDate()
        );
        this.series[6].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0 ? 0 : month.kpis_array[10].kpi_value.toFixed(2)
        );

        this.series[7].data = this.KPIsArray.map((month) =>
          month.kpis_array.length == 0 ? 0 : 130 * new Date(month.kpi_year, month.kpi_month, 0).getDate()
        );
        this.mounted = true;
      });
    },
  },
};
</script>
