import { API } from "aws-amplify";

export const getMTDdata = {
  data: () => ({
    GetAllWaterInletData: [],
    overlay: false,
    noDataText: "",
  }),
  methods: {
    async GetAllMTDdataMethods(plant_name, input_date, mtd_type) {
      var self = this;
      self.overlay = true;
      const path = "removealarmconfiguration";
      const myInit = {
        body: {
          command: "getMTDData",
          organization_id: this.$store.getters.get_asset_object.organization_id,
          asset_id: this.$store.getters.get_asset_object.asset_id,
          plant_name: plant_name,
          input_date: input_date,
          mtd_type: mtd_type,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          self.overlay = false;
          if (!response.errorType) {
            this.GetAllWaterInletData = response.data.items;
          } else {
            self.GetAllWaterInletData = [];
            self.noDataText = response.errorMessage;
          }
          console.log("GetAllWaterInletData", this.GetAllWaterInletData);
        })
        .catch((error) => {
          self.overlay = false;
          console.warn(error);
        });
    },
  },
};
