var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('FilterMonthAndYearDialog',{attrs:{"FilterYearAndMonthDialog":_vm.FilterYearAndMonthDialog},on:{"clicked":_vm.FilterYearAndMonthDialogEmit}}),_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[_c('v-card',{staticClass:"elevation-2 overflow-auto ma-1",attrs:{"height":"310px"}},[_c('v-toolbar',{staticClass:"py-0 pa-0 ma-0",attrs:{"dense":""}},[_c('div',{staticClass:"heading1 PrimaryFontColor"},[_vm._v("KPI's")]),_c('v-spacer'),_c('v-select',{staticClass:"FontSize field_label_size field_height maxWidth mr-2 pt-7",attrs:{"outlined":"","dense":"","multiple":"","label":"Select Header","items":_vm.header,"item-text":"text","item-value":"value","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"x-small":""}},[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text"},[_vm._v("+"+_vm._s(_vm.SelectedHeader.length - 1)+" Others")]):_vm._e()]}}]),model:{value:(_vm.SelectedHeader),callback:function ($$v) {_vm.SelectedHeader=$$v},expression:"SelectedHeader"}}),_c('v-btn',{staticClass:"text-capitalize",attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){_vm.FilterYearAndMonthDialog = true}}},[_vm._v("Select Year And Month")])],1),_c('v-data-table',{staticClass:"KPITableClass rightSideTree leftSideTree",attrs:{"dense":"","headers":_vm.SelectedHeader,"fixed-header":"","height":"230","items":_vm.KPIsArray,"items-per-page":12,"no-data-text":"No Data Available","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.kpi_year",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpi_year ? item.kpi_year : "-")+" ")])]}},{key:"item.month_text",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.month_text ? item.month_text : "-")+" ")])]}},{key:"item.uffl_water_inlet",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[16].kpi_value.toFixed(2) : "-")+" ")])]}},{key:"item.uffl_percentage_of_rated_capacity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[15].kpi_value.toFixed(2) : "-")+" ")])]}},{key:"item.uf_water_produced",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? typeof item.kpis_array[14].kpi_value == "number" ? item.kpis_array[14].kpi_value.toFixed(2) : item.kpis_array[14].kpi_value : "-")+" ")])]}},{key:"item.uf_rated_capacity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[12].kpi_value.toFixed(2) : "-")+" ")])]}},{key:"item.ro1_water_produced",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[6].kpi_value.toFixed(2) : "-")+" ")])]}},{key:"item.ro1_rated_capacity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[4].kpi_value.toFixed(2) : "-")+" ")])]}},{key:"item.ro2_water_produced",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[10].kpi_value.toFixed(2) : "-")+" ")])]}},{key:"item.ro2_rated_capacity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[8].kpi_value.toFixed(2) : "-")+" ")])]}},{key:"item.overall_plant_recovery",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[1].kpi_value : "-")+" ")])]}},{key:"item.previous_day_plant_recovery",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[2].kpi_value : "-")+" ")])]}},{key:"item.dmf_backwash_and_rinse",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[0].kpi_value.toFixed(2) : "-")+" ")])]}},{key:"item.uf_total_hours_of_operation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[13].kpi_value : "-")+" ")])]}},{key:"item.uf_down_time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[11].kpi_value : "-")+" ")])]}},{key:"item.ro1_total_hours_of_operation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[5].kpi_value : "-")+" ")])]}},{key:"item.ro1_down_time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[3].kpi_value : "-")+" ")])]}},{key:"item.ro2_total_hours_of_operation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? typeof item.kpis_array[9].kpi_value == "number" ? item.kpis_array[9].kpi_value : item.kpis_array[9].kpi_value : "-")+" ")])]}},{key:"item.ro2_down_time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.kpis_array.length != 0 ? item.kpis_array[7].kpi_value : "-")+" ")])]}},_vm._l((_vm.header),function(h,i){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('div',{key:i},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip_text))])])],1)]}}})],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12","xs":"12"}},[_c('v-card',{staticClass:"elevation-2 overflow-hidden ma-1 leftSideTree",attrs:{"height":"300px"}},[(_vm.RenderComponent)?_c('LineChart',{attrs:{"KPIsArray":_vm.KPIsArray}}):_vm._e()],1)],1),(_vm.KPIsArray.length != 0)?_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12","xs":"12"}},[(_vm.KPIsArray.length != 0)?_c('v-card',{staticClass:"elevation-2 overflow-hidden ma-1 leftSideTree",attrs:{"height":"300px"}},[(_vm.RenderComponent)?_c('BarChart',{attrs:{"KPIsArray":_vm.KPIsArray}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.KPIsArray.length != 0)?_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[_c('v-card',{staticClass:"elevation-2 overflow-auto overflow-hidden ma-1 leftSideTree",attrs:{"height":"300px"}},[_c('GroupBarChart')],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }