import { API } from "aws-amplify";
export const GetKPIs = {
  data() {
    return {
      overlay: false,
      GetKPIsParameters: {},
    };
  },
  methods: {
    async GetKPIsMethod(input_dates) {
      this.loading = true;
      this.overlay = true;
      let arraytest = [];
      var self = this;
      const path = "removealarmconfiguration";
      const myInit = {
        body: {
          command: "getKPIsData",
          organization_id: this.$store.getters.get_asset_object.organization_id,
          asset_id: this.$store.getters.get_asset_object.asset_id,
          input_dates: input_dates,
        },
      };
      await API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          console.log("Rsss", response);
          if (!response.errorType) {
            self.loading = false;
            self.overlay = false;
            arraytest = response.data.items.map((item) => {
              let object = item;
              object.month_text = this.GetMonth.filter((month) => month.value == item.kpi_month)[0].text;
              return object;
            });
            this.KPIsArray = arraytest;
            console.log("Array Tesss", arraytest);
          } else {
            self.loading = false;
            self.overlay = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.overlay = false;
        });

      return arraytest;
    },
  },
};
